import React from "react";
import { graphql } from "gatsby";
import Errors from "../components/errors";
import SEO from "../components/seo";
import Layout from "../containers/layout";

import Intro from "../components/intro-hero";
import WhyUs from "../components/why-us"
import BookNow from "../components/book-now";
import Slideshow from "../components/slideshow/slideshow";
import GrizzlyLook from "../components/grizzly-look";
import Locations from "../components/locations";
import Skew from "../components/skew";

export const query = graphql`
  query FrontpageQuery {
    page: sanityLandingPage(_id: { regex: "/(drafts.|)landingPage/" }) {
      ...PageInfo
    }

    locations: allSanityLocations(sort: {fields: _createdAt, order: ASC}) {
      edges {
          node {
            name
            slug {
              current
            }
          }
        }
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      name
      announcement {
        enabled
      }
      ...NavMenu
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return <Errors errors={errors} />;
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const page = data.page || data.route.page;
  const locations = (data.locations || {}).edges || [];
  const images = page?.images
  const menuItems = site.navMenu && (site.navMenu.items || []);
  const hasAnnouncement = site.announcement.enabled

  const content = (page._rawContent || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null;
      switch (c._type) {
        case "intro":
          el = <Intro key={c._key} {...c} />;
          break;
        case "whyus":
          el = <WhyUs key={c._key} {...c} />;
          break;
        case "booknow":
          el = <BookNow key={c._key} {...c} />;
          break;
        default:
          el = null;
      }
      return el;
    });

  return (
    <Layout navMenuItems={menuItems} nav={site.navMenu} textWhite={true} >
      <SEO
        title={page.title}
        bodyAttr={{
          class: "leading-normal tracking-normal text-white bg-gray-800"
        }}
      />
      <div className="">
        <Slideshow images={images} hasAnnouncement={hasAnnouncement} />
        <Skew />

        {content}

        <Locations locations={locations} />

        <GrizzlyLook />
      </div>
    </Layout >
  );
};

export default IndexPage;
