import React from "react"

const BookNow = ({ text, link, url }) => {
  return (
    <section className="flex flex-col md:flex-row bg-green-800 text-white p-12 justify-center items-center">
      <p className="uppercase md:mr-8 md:text-lg font-medium">{text}</p>
      <a href={url} target="_blank" className="mt-8 md:mt-0 border-4 uppercase p-3 hover:bg-gray-800 hover:text-white hover:border-gray-800">{link}</a>
    </section>
  )
}

export default BookNow
