import React from "react"
import { Link } from "gatsby"

const Locations = ({ locations }) => {
  return (
    <section id="locations" className="px-24 py-12 text-center">
    <div className="flex justify-center flex-wrap">
      <h1 className="w-full text-3xl md:text-5xl font-bold leading-tight text-center text-white">
        Locations
      </h1>
      {locations.map(({ node: location }, idx) => {
        return (
          <div key={idx} className="flex flex-col sm:flex-row justify-center pt-6 md:pt-12 md:mr-6">
            <div className="bg-white flex flex-col mx-auto lg:mx-0 mt-4 w-96 min-w-full">
              <div className="flex-1 text-gray-600 overflow-hidden">
                <div className="p-8 text-xl md:text-3xl font-bold text-center">{location.name}</div>
              </div>
              <div className="flex-none mt-auto overflow-hidden p-6">
                <div className="flex items-center justify-center">
                  <Link to={`/${location.slug.current}`}>
                    <div className="mb-5 mt-8 border-2 px-5 text-gray-900 border-gray-800 uppercase py-3 hover:bg-green-800 hover:border-green-900 hover:text-white font-medium">View Now</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  </section>
  )
}

export default Locations
