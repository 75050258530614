import React from "react"
import { Link } from "gatsby"
import LazyLoad from 'react-lazyload'
import { Heading, Subheading } from '../components/headings'

const CustomLink = ({ link, route, children }) => {
  if (route) {
    const path = `/prices#${route}`
    return (
      <Link to={path}>
        {children}
      </Link>
    )
  }
  return (
    <a href={link} target="_blank">
      {children}
    </a>
  )
}

const Section = ({ title, description, link, route, image }) => {
  const imageUrl = image.asset.url
  return (
    <CustomLink route={route} link={link}>
      <div className="relative shadow-md hover:shadow-lg product transition-all	overflow-hidden">
        <figure>
          <img height="400" width="300" loading="lazy" className="w-full object-cover transition-all product-image" src={imageUrl} alt={title} />
          <figcaption>
            <div className="products-content text-gray-900">
              <p className="pt-8 font-medium uppercore text-lg text-center">{title}</p>
              <p className="text-center transition-all product-description">{description}</p>
            </div>
          </figcaption>
        </figure>
      </div>
    </CustomLink>
  )
}

const Intro = ({ title, description, subtitle, links }) => {
  return (
    <section className="flex justify-center flex-col p-12 md:p-24 items-center">
      <div className="text-center max-w-screen-lg">
        <p className="font-medium text-green-800 text-lg uppercase">{subtitle}</p>
        <Heading text={title} />
        <Subheading text={description} />
      </div>
      <div className="max-w-screen-xl mt-12 grid sm:grid-cols-1 md:grid-cols-4 gap-4">
        {links.map((link, idx) => {
          return <LazyLoad key={idx} height={400}>
            <Section {...link} />
          </LazyLoad>
        })}
      </div>
    </section>
  )
}


export default Intro
