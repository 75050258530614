import React from 'react'
import Slider from "react-animated-slider";
import { imageUrlFor } from "../../lib/image-url";
import { buildImageObj } from "../../lib/helpers";
import "react-animated-slider/build/horizontal.css";
import './slideshow.css'
import './slideshow.animation.css'

const PreviousArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 30"><polygon fill="#000" points="20 15 4.228 0 0 3.626 11.954 15 0 26.374 4.228 30" transform="rotate(180 10 15)"></polygon></svg>
  )
}
const NextArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 30"><polygon fill="#000" points="20 15 4.228 0 0 3.626 11.954 15 0 26.374 4.228 30" transform="rotate(0 10 15)"></polygon></svg>
  )
}

const Slideshow = ({ images, hasAnnouncement = false }) => {
  return (
    <section className={`relative ${hasAnnouncement && 'md:mt-16'}`} style={{ height: '85vh' }}>
      <Slider
        className="overflow-hidden relative h-full"
        autoplay="5000"
        infinite={true}
        touchDisabled={true}
        classNames={{
          track: 'h-full',
          previousButton: 'absolute ml-4 rounded-full bg-gray-300 bg-opacity-50 cursor-pointer w-12 h-12 left-0 top-1/2 z-20 transform -translate-y-2/4 flex text-center items-center justify-center',
          nextButton: 'absolute mr-4 rounded-full bg-gray-300 bg-opacity-50 cursor-pointer w-12 h-12 right-0 top-1/2 z-20 transform -translate-y-2/4 flex text-center items-center justify-center'
        }}
        previousButton={<PreviousArrow />}
        nextButton={<NextArrow />}
      >
        {images.map((image, idx) => {
          const img = imageUrlFor(buildImageObj(image))
            .width(1200)
            .url()
          return (
            <div
              key={idx}
              className="text-center slider-content"
              style={{ background: `url('${img}') no-repeat center center` }}
            >
              <div className="text-box border-box flex flex-col items-center p-6 md:p-12 z-20 min-w-min border-2 bg-gray-900 bg-opacity-50 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                <div className="box-icon rounded-full border-4 w-20 h-20 p p-12 flex items-center justify-center">
                  <i className={`${image.icon} text-6xl text-white`}></i>
                </div>
                <h2 className="font-extrabold uppercase text-3xl md:text-5xl lg:text-6xl pt-4">{image.heading}</h2>
                <p className="text-sm md:text-xl font-bold pt-4">{image.text}</p>
              </div>
            </div>
          )
        })}
      </Slider>
    </section>
  )
}

export default Slideshow
