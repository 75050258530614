import { graphql, StaticQuery } from "gatsby";
import React, { useState } from "react";
import Layout from "../components/layout";

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      name
      announcement {
        enabled
        mobile
        desktop
      }
    }
    footer: sanityFooter(_id: { regex: "/(drafts.|)footer/" }) {
      heading
      subheading
      email
      phone
      address
      image {
        ...SanityImage
      }
      _rawSocial(resolveReferences: {maxDepth: 10})
    }
  }
`;

function LayoutContainer(props) {
  const [showNav, setShowNav] = useState(true);
  function handleShowNav() {
    setShowNav(true);
  }
  function handleHideNav() {
    setShowNav(false);
  }

  const { textWhite = false } = props;

  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          );
        }
        return (
          <>
            <Layout
              {...props}
              showNav={showNav}
              siteTitle={data.site.name}
              onHideNav={handleHideNav}
              onShowNav={handleShowNav}
              textWhite={textWhite}
              footer={data.footer}
              announcement={data.site.announcement}
            />
            {/* <p>Add Cookie Consent Here</p> */}
          </>
        );
      }}
    />
  );
}

export default LayoutContainer;
