import React from "react"
import { Parallax } from "react-parallax"

const Section = ({ title, description, icon }) => {
  return (
    <div className="flex mb-8">
      <div className="h-24 w-24 mr-8">
        <i className={`${icon} text-6xl text-white`}></i>
      </div>
      <div>
        <p className="font-bold text-green-800 text-lg uppercase">{title}</p>
        <p className="text-white font-bold">{description}</p>
      </div>
    </div>
  )
}

const WhyUs = ({ title, image, subtitle, sections }) => {
  return (
    <section>
      <Parallax
        bgImage={`${image.asset.url}?w=1200`}
        bgImageStyle={{ width: "100%", objectFit: "cover", opacity: ".5" }}
        blur={1}
        strength={400}
      >
        <div className="p-16 md:pl-16 md:pt-16 md:pb-8">
          <div className="md:w-1/2 lg:w-3/5">
            <p className="font-medium text-green-800 md:text-lg uppercase">{subtitle}</p>
            <h2 className="mr-5 lg:mr-40 text-2xl md:text-4xl lg:text-6xl leading-none bold uppercase text-white">{title}</h2>

            <div className="pt-6 md:max-w-md">
              {sections.map((section, idx) => {
                return (
                  <Section key={idx} {...section} />
                )
              })}
            </div>
          </div>
        </div>
      </Parallax>
    </section>
  )

}


export default WhyUs
