import React from "react";

export const Heading = ({ text }) => {
  return (
    <h2 className="text-4xl lg:text-6xl uppercase text-white">{text}</h2>
  )
}

export const Subheading = ({ text }) => {
  return (
    <h3 className="text-lg text-gray-400 mt-6">{text}</h3>
  )
}
