import React from "react";

const GrizzlyLook = () => {
  return (
    <div className="bg-green-800">
      <p className="text-center white uppercase font-medium text-lg py-12">Get the Grizzly look</p>
    </div>
  )
}

export default GrizzlyLook
